import DataList from "../../components/data-list";

const MembersData = () => {
  return (
    <>
      <h1>Members' data</h1>
      <section className="members__data">
        <DataList />
      </section>
    </>
  );
};

export default MembersData;
