import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import logo from "../../../../assets/logo.svg";
import { ReactComponent as EditIcon } from "../../../../assets/admin-edit-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/admin-delete-icon.svg";

function createData(
  avatar,
  name,
  email,
  number,
  age,
  institution,
  faculty,
  department
) {
  return {
    avatar,
    name,
    email,
    number,
    age,
    institution,
    faculty,
    department,
  };
}
const ROWS = [
  createData(
    logo,
    `Muhammed Tijani`,
    `tijanimuhammed84@gmail.com`,
    `080999666777`,
    20,
    `University of Ibadan`,
    `Tech`,
    `MechE`
  ),
  createData(
    logo,
    `Muhammed Tijani`,
    `tijanimuhammed84@gmail.com`,
    `080999666777`,
    20,
    `University of Ibadan`,
    `Tech`,
    `MechE`
  ),
  createData(
    logo,
    `Muhammed Tijani`,
    `tijanimuhammed84@gmail.com`,
    `080999666777`,
    20,
    `University of Ibadan`,
    `Tech`,
    `MechE`
  ),
  createData(
    logo,
    `Muhammed Tijani`,
    `tijanimuhammed84@gmail.com`,
    `080999666777`,
    20,
    `University of Ibadan`,
    `Tech`,
    `MechE`
  ),
  createData(
    logo,
    `Muhammed Tijani`,
    `tijanimuhammed84@gmail.com`,
    `080999666777`,
    20,
    `University of Ibadan`,
    `Tech`,
    `MechE`
  ),
  createData(
    logo,
    `Muhammed Tijani`,
    `tijanimuhammed84@gmail.com`,
    `080999666777`,
    20,
    `University of Ibadan`,
    `Tech`,
    `MechE`
  ),
  createData(
    logo,
    `Muhammed Tijani`,
    `tijanimuhammed84@gmail.com`,
    `080999666777`,
    20,
    `University of Ibadan`,
    `Tech`,
    `MechE`
  ),
  createData(
    logo,
    `Muhammed Tijani`,
    `tijanimuhammed84@gmail.com`,
    `080999666777`,
    20,
    `University of Ibadan`,
    `Tech`,
    `MechE`
  ),
  createData(
    logo,
    `Muhammed Tijani`,
    `tijanimuhammed84@gmail.com`,
    `080999666777`,
    20,
    `University of Ibadan`,
    `Tech`,
    `MechE`
  ),
  createData(
    logo,
    `Muhammed Tijani`,
    `tijanimuhammed84@gmail.com`,
    `080999666777`,
    20,
    `University of Ibadan`,
    `Tech`,
    `MechE`
  ),
];
const AdminTable = () => {
  return (
    <section className="admin-table">
      <h1>Members' data</h1>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Num</TableCell>
              <TableCell>Age</TableCell>
              <TableCell>Institution</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ROWS.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <img src={logo} alt="placeholder" width={60} height={60} />
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.age}</TableCell>
                <TableCell>{row.number}</TableCell>
                <TableCell>
                  <EditIcon />
                </TableCell>
                <TableCell>
                  <DeleteIcon />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
    </section>
  );
};
export default AdminTable;
