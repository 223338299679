import React from "react";
import "./index.css";

const Location = () => {
  return (
    <section>
      <div className="location__map-image"></div>
      <div className="location">
        <h1 className="location__heading">Our Locations</h1>
        <div className="location__buttons">
          <span>Ibadan</span>
        </div>
      </div>
    </section>
  );
};

export default Location;
